.custom-gradient {
    background: linear-gradient(to right, #173336 0%, #325858 100%);
}

.text-custom-gradient {
    background: linear-gradient(to right, #173336 0%, #325858 100%);
    background-clip: text;
    color: transparent;
}
.url-icon:hover .url-icon-svg {
    fill: #173336;
}
.signin-custom-gradient {
    background: linear-gradient(45deg, #173336 0%, #325858 34%, #173336 81%);
}
.gradient-background-vertical {
    background: linear-gradient(to bottom, #173336 0%, #325858 100%);
}

.gradient-background-down {
    background: linear-gradient(to top, #173336 0%, #325858 100%);
}
.active-price-plan {
    background: linear-gradient(to top, #173336 0%, #325858 100%);
    color: white;
}
.active-price-plan {
    button,
    h3,
    p,
    ul,
    li {
        /* color: rgba(255, 255, 255, 0.5); */
    }
}
.active-price-plan svg {
    color: white;
    background-color: rgb(55, 95, 95);
}

.in-active-price-plan {
    button {
        background: linear-gradient(to right, #173336 0%, #325858 100%);
    }
    h3,
    p,
    ul,
    li {
    }
}
.in-active-price-plan ul svg {
    color: rgba(23, 51, 54, 1);
    background-color: rgba(214, 229, 226, 1);
}

.text-inherit {
    color: inherit !important;
}
.css-1bs3211-control {
    height: 44px;
}
